export default function setPurchaseUserBody(data: any) {
  return {
    first_name: data.participants[0].firstName,
    last_name: data.participants[0].lastName,
    accept_terms: data.acceptTerms,
    email: data.participants[0].email,
    phone: data.participants[0].phone,
    dni: data.participants[0].dni,
    address: data.participants[0].address,
    birth_date: data.participants[0].birthDate,
    booking_country: data.country,
    booking_city: data.city,
    postal_code: data.postalCode,
  }
}
