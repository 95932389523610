import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import axiosRetry from 'axios-retry'

import { IGET, IPOST, ServicesTypes } from './axios.interface'

interface ErrorResponse {
  data: any
  statusCode: number
}

axiosRetry(axios, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (e: AxiosError) =>
    e.config.method === 'get' &&
    axiosRetry.isNetworkOrIdempotentRequestError(e),
})

axios.defaults.headers = {
  common: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Credentials': 'true',
  },
  get: {},
  post: {},
  delete: {},
  put: {},
  patch: {},
  head: {},
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  return config
})

const serviceType = (type: ServicesTypes = ServicesTypes.api): string => {
  if (!type) return import.meta.env.VITE_ROOT_API || ''

  const types = {
    [ServicesTypes.api]: import.meta.env.VITE_ROOT_API,
    [ServicesTypes.google]: 'https://www.googleapis.com/',
    [ServicesTypes.newApi]: import.meta.env.VITE_NEST_API,
  }
  return types[type]
}

const callService = <T>(call: AxiosRequestConfig): Promise<T> => {
  return (async (): Promise<T> => {
    try {
      const response = await axios(call)
      return response.data
    } catch (error) {
      const response = error.response
        ? error.response
        : { data: error.message, status: 404 }
      const newError: ErrorResponse = {
        data: response.data,
        statusCode: (response && response.status) || 404,
      }
      throw newError
    }
  })()
}

export const get = <T>({
  path,
  params,
  headers,
  type,
  ...rest
}: IGET): Promise<T> =>
  callService({
    method: 'GET',
    url: `${serviceType(type)}${path}`,
    headers: {
      ...headers,
      'Api-Token': import.meta.env.VITE_API_TOKEN,
      'Lang-Code':
        localStorage.getItem('locale') || window.navigator.language || 'es',
    },
    params,
    ...rest,
  })

export const post = <T>({
  path,
  data,
  headers,
  type,
  ...rest
}: IPOST): Promise<T> =>
  callService({
    method: 'POST',
    url: `${serviceType(type)}${path}`,
    headers: {
      ...headers,
      'Api-Token': import.meta.env.VITE_API_TOKEN,
      'Lang-Code':
        localStorage.getItem('locale') || window.navigator.language || 'es',
    },
    data,
    ...rest,
  })
