import CleverTapWeb from 'clevertap-web'
import { get, post } from '@/services/axios/axios'
import {
  setFavoriteExperiencesAction,
  setLoggedInAction,
  setPurchasesAction,
  setShowAlert,
  setUserDataAction,
  setBookingDataAction,
  setIsNewUserAction,
  setIsProcessingPaymentAction,
  setLoadingPaymentAction,
} from 'store/actions'
import { ActionType } from 'store/actions/base-action'
import { ERROR, FAILED, SUCCESS } from '@/constants/common'
import { identifyUser, track, googleTrack } from '@/utils/analytics'
import { User } from '@/models/user'

export const mapUserData = (data) => {
  const user: User = {
    fullName: `${data.first_name} ${data.last_name}`,
    firstName: data.first_name,
    lastName: data.last_name || '',
    email: data.email,
    country: data.country || '',
    birthDate: data.birth_date || '',
    address: data.address || '',
    language: data.language || '',
    avatar: data.avatar_url,
    phone: data?.phone || '',
    dni: data?.dni || '',
    id: data.id,
    balance: data?.balance || 0,
    referent: data?.referred_by || '',
    isAdmin: data?.is_admin || '',
    bookingCountry: data?.booking_country || '',
    bookingCity: data?.booking_city || '',
    postalCode: data?.postal_code || '',
    isAutogenerated: data?.is_autogenerated || false,
    new_user: data?.new_user || false,
  }
  return user
}

export const saveLocalUserData = (userData: User) => {
  window.localStorage.setItem('userData', JSON.stringify(userData))
  window.localStorage.setItem('token', JSON.stringify(userData.id))
}

interface SocialSignInData {
  password: string
  first_name: string
  last_name: string
  email: string
  avatar_url: string
  type_register: number
  referent_code?: string
}

export const useHandleUserSession = (
  dispatch: (action: ActionType) => void
) => {
  const handleSignIn = async (data) => {
    const response = await post({
      path: 'sign-in',
      data: data,
    })
      .then(({ data }: any) => {
        const userData = mapUserData(data)
        dispatch(setUserDataAction(userData))
        dispatch(setLoggedInAction(!!userData.id))
        saveLocalUserData(userData)
        dispatch(
          setShowAlert({
            show: true,
            type: SUCCESS,
            message: 'signInForm.signInSuccess',
          })
        )
        identifyUser(userData)
        track('Email Login Submit', {
          submitResult: FAILED,
        })
        return true
      })
      .catch(() => {
        track('Email Login Submit', {
          submitResult: FAILED,
        })
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'signInForm.errors.incorrectPassword',
          })
        )
        return false
      })

    if (response) data.onClose()

    return !!response
  }

  const handleSignOut = () => {
    window.localStorage.removeItem('userData')
    // localStorage.clear()
    dispatch(
      setShowAlert({
        show: true,
        type: SUCCESS,
        message: 'navbar.signOutSuccess',
      })
    )
    dispatch(setUserDataAction(null))
    dispatch(setLoggedInAction(false))
    dispatch(setPurchasesAction(null))
    dispatch(setFavoriteExperiencesAction(null))
    CleverTapWeb.logout()
    location.reload()
  }

  const handleSignUp = async (data) => {
    const referentCode = null //localStorage.getItem('referentCode')
    /*if (referentCode) {
      data.body.referent_code = referentCode
    } */
    const response = await post({
      path: 'sign-up',
      data: data.body,
    })
      .then(({ data }: any) => {
        const userData = mapUserData(data)
        localStorage.removeItem('referentCode')
        dispatch(setUserDataAction(userData))
        dispatch(setLoggedInAction(!!userData.id))
        saveLocalUserData(userData)
        identifyUser(userData)

        dispatch(
          setShowAlert({
            show: true,
            type: SUCCESS,
            message: 'signUpForm.signUpSuccess',
          })
        )
        track('SignUp Submit', {
          submitResult: SUCCESS,
        })
        googleTrack('SignUp', {
          method: 'Email',
          referent: referentCode || '',
        })
        return true
      })
      .catch(() => {
        track('SignUp Submit', {
          submitResult: ERROR,
        })
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'signUpForm.errors.verifyInformation',
          })
        )
        return false
      })
    if (response) data.handleSignUpSuccess()
  }

  const handleAutoSignUp = async (data) => {
    dispatch(setIsProcessingPaymentAction(true))
    dispatch(setLoadingPaymentAction(true))
    const response = await post({
      path: 'sign-up-auto',
      data,
    })
      .then(({ data }: any) => {
        dispatch(setIsNewUserAction(data?.new_user ?? false))
        const userData = mapUserData(data)
        if (data?.new_user ?? false) {
          dispatch(setLoggedInAction(!!userData.id))
          dispatch(setUserDataAction(userData))
          saveLocalUserData(userData)
          identifyUser(userData)
          // dispatch(
          //   setShowAlert({
          //     show: true,
          //     type: SUCCESS,
          //     message: 'signInForm.signInSuccess',
          //   })
          // )
        }
        return userData
      })
      .catch(() => {
        // track('SignUp Submit', {
        //   submitResult: ERROR,
        // })
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'signUpForm.errors.verifyInformation',
          })
        )
        return false
      })
    return response
  }

  const handleSocialSignIn = async (params, onSuccess) => {
    const referentCode = null //localStorage.getItem('referentCode')
    const data: SocialSignInData = {
      password: params.kid || params.jti || '',
      first_name: params.given_name,
      last_name: params.family_name,
      email: params.email,
      avatar_url: params.picture,
      type_register: 3,
    }
    /* if (referentCode) {
      data.referent_code = referentCode
    } */

    const response = await post({
      path: 'social-auth',
      data,
    })
      .then(({ data }) => {
        if (data.date_last_activity === data.date_register) {
          track('Email SignUp Third Party', {
            submitResult: SUCCESS,
            emailProvider: 'Google',
          })
          googleTrack('SignUp', {
            method: 'Google',
            referent: referentCode || '',
          })
        } else {
          track('Email Login Third Party', {
            submitResult: SUCCESS,
            emailProvider: 'Google',
          })
        }
        const userData = mapUserData(data)
        saveLocalUserData(userData)
        localStorage.removeItem('referentCode')
        dispatch(setLoggedInAction(true))
        dispatch(setUserDataAction(userData))
        identifyUser(userData)
        onSuccess()
      })
      .catch((error) => {
        track('Email SignUp Third Party', {
          submitResult: FAILED,
          emailProvider: 'Google',
        })
        console.error(error)
      })
  }

  const getBalance = async (user) => {
    await get({
      path: `get-wallet/${user?.id}`,
    }).then((result: any) => {
      let balance = result?.data.available_balance
      dispatch(setUserDataAction({ ...user, balance: balance }))
      saveLocalUserData({ ...user, balance: balance })
    })
  }

  return {
    handleSignIn,
    handleSignOut,
    handleSignUp,
    handleSocialSignIn,
    getBalance,
    handleAutoSignUp,
  }
}
