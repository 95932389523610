import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { NextUIProvider } from '@nextui-org/react'
import { ContextProvider } from 'hooks/useGotuuriContext'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { MainPage } from 'pages/MainPage'
import './tailwind.css'
import { useListenTranslate } from 'hooks/useListenTranslate'
import NotFoundPage from '@/pages/NotFoundPage/NotFoundPage'
import { DestinationPage } from 'pages/DestinationPage'
import { HighlightPage } from './pages/HighlightPage'
import { DestinationsPage } from './pages/DestinationsPage'
import { HighlightsPage } from './pages/HighlightsPage'
import { CategoriesPage } from './pages/CategoriesPage'
import { CountriesPage } from './pages/CountriesPage'
import { TuuriRegisterPage } from './pages/TuuriRegisterPage'

import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'
import { AlertToast } from './components/AlertToast'
import { initializeClevertap } from './utils/analytics'
import { SearchPage } from './pages/SearchPage'
import TagManager from 'react-gtm-module'
import { HelmetProvider } from 'react-helmet-async'
import RedeemPage from './pages/GiftCardPage/RedeemPage'
import BalancePage from './pages/GiftCardPage/BalancePage'
import ScrollToTop from './ScrollToTop'
import { Path } from './constants/path'
import { Loading } from './components/Loading'
import { isCookie, createCookie } from '@/utils/cookie.Util'
const UnsubscribePage = lazy(
  () => import('./pages/UnsubscribePage/UnsubscribePage')
)
const StaticContentPage = lazy(
  () => import('./pages/StaticContentPage/StaticContentPage')
)
const AboutUsPage = lazy(() => import('./pages/AboutUsPage/AboutUsPage'))
const FaqsPage = lazy(() => import('./pages/FaqsPage/FaqsPage'))
const ContactPage = lazy(() => import('./pages/ContactPage/ContactPage'))
const ReferentPage = lazy(() => import('./pages/ReferentPage/ReferentPage'))
const AgendaPage = lazy(() => import('./pages/AgendaPage/AgendaPage'))
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'))
const FavoritePage = lazy(() => import('./pages/FavoritePage/FavoritePage'))
const ExperiencePage = lazy(
  () => import('./pages/ExperiencePage/ExperiencePage')
)
const BookingDetailsPage = lazy(
  () => import('./pages/BookingDetailsPage/BookingDetailsPage')
)
const PaymentPage = lazy(() => import('./pages/PaymentPage/PaymentPage'))
const GiveAnExperience = lazy(
  () => import('./pages/GiveAnExperience/GiveAnExperience')
)
const GiveAnExperiencePayment = lazy(
  () => import('./pages/GiveAnExperiencePayment/GiveAnExperiencePayment')
)
import { AFFILIATE_CODE_NAME, PARAMETER_AFFILIATE } from '@/constants/cookie'

import { CyberSource3DSRedirect } from './pages/CyberSource3DSRedirect'
import { useListenLocalStorage } from './hooks/useListenLocalStorage'

export const App = () => {
  const GTM_ID = import.meta.env.VITE_GTM || ''
  const tagManagerArgs = {
    gtmId: GTM_ID,
  }
  useListenTranslate()

  useEffect(() => {
    let cookieName = AFFILIATE_CODE_NAME
    let parameterAfilliate = PARAMETER_AFFILIATE
    const urlParams = new URLSearchParams(window.location.search)
    const cookieValue = urlParams.get(parameterAfilliate)

    if (isCookie(cookieName, cookieValue)) {
      if (cookieValue !== null) {
        createCookie(cookieName, cookieValue)
      }
    } else {
      // Extraer el parámetro de consulta 'cookieName'

      if (cookieValue) {
        // Almacenar en cookies con una duración de 30 días
        createCookie(cookieName, cookieValue)
      }
    }

    TagManager.initialize(tagManagerArgs)
    return () => {
      initializeClevertap()
    }
  }, [])

  const helmetContext = {}
  const { locale } = useListenLocalStorage(['locale'])

  const nextUiLocale = () => {
    if (typeof window != 'undefined') {
      const lang = locale as string
      const validLanguages = { es: 'es-Es', en: 'en-US' }
      return validLanguages[lang] || 'es-Es'
    }
  }

  const GOOGLE_ID = import.meta.env.VITE_GOOGLE_ID
  return (
    <ContextProvider>
      <GoogleOAuthProvider clientId={GOOGLE_ID || ''}>
        <HelmetProvider context={helmetContext}>
          <NextUIProvider className='flex flex-col flex-grow h-screen' locale={nextUiLocale()}>
            <Navbar />
            <div className="flex flex-col h-screen justify-between">
              <ScrollToTop />
              <Suspense fallback={<Loading fullHeight={true} />}>
                <Routes>
                  <Route path="/" index Component={MainPage} />
                  {/* <Route path={Path.COUNTRY_HOME} Component={MainPage} /> */}
                  <Route path="/about" Component={AboutUsPage} />
                  <Route path="*" Component={NotFoundPage} />
                  <Route path="/faqs" Component={FaqsPage} />
                  <Route path="/contact" Component={ContactPage} />
                  <Route
                    path={Path.EXPERIENCE_PAGE}
                    Component={ExperiencePage}
                  />
                  <Route path="/agenda/:section?" Component={AgendaPage} />
                  <Route path="/destinations" Component={DestinationPage} />
                  <Route
                    path="/destinations/country/:countryName/:id"
                    Component={DestinationPage}
                  />
                  <Route path="/highlights" Component={HighlightPage} />
                  <Route
                    path="/destinations/:title/:id"
                    Component={DestinationsPage}
                  />
                  <Route
                    path="/highlights/:title/:id"
                    Component={HighlightsPage}
                  />
                  <Route
                    path="/categories/:title/:id"
                    Component={CategoriesPage}
                  />
                  <Route path="/country/:title/:id" Component={CountriesPage} />
                  <Route path="/profile/:section?" Component={ProfilePage} />
                  <Route
                    path="/profile/credit-giftcard/redeem"
                    Component={RedeemPage}
                  />
                  <Route
                    path="/profile/credit-giftcard/balance"
                    Component={BalancePage}
                  />
                  <Route
                    path="/booking/details"
                    Component={BookingDetailsPage}
                  />
                  <Route path="/booking/payment" Component={PaymentPage} />
                  <Route path="/search/:text?" Component={SearchPage} />
                  <Route path="/referent/:referent" Component={ReferentPage} />
                  <Route
                    path="/terms-and-conditions"
                    Component={StaticContentPage}
                  />
                  <Route
                    path="/privacy-policies"
                    Component={StaticContentPage}
                  />
                  <Route
                    path="/chubb-clients-coverage"
                    Component={StaticContentPage}
                  />
                  <Route
                    path="/chubb-tuuris-coverage"
                    Component={StaticContentPage}
                  />
                  <Route path={Path.GIFTCARDS} Component={GiveAnExperience} />
                  <Route
                    path={Path.GIFTCARDS_PAYMENT}
                    Component={GiveAnExperiencePayment}
                  />
                  <Route path="/favorites" Component={FavoritePage} />
                  <Route path="/unsubscribe" Component={UnsubscribePage} />
                  {/* <Route path="/tuuri-register" Component={TuuriRegisterPage} /> */}
                  <Route
                    path="/cybersource-3ds-redirect"
                    Component={CyberSource3DSRedirect}
                  />
                </Routes>
              </Suspense>
              <Footer />
            </div>
            <AlertToast />
          </NextUIProvider>
        </HelmetProvider>
      </GoogleOAuthProvider>
    </ContextProvider>
  )
}
