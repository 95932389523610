import { ERROR, SUCCESS } from '@/constants/common'
import { DEFAULT_PAYMENT_METHOD } from '@/constants/paymentMethods'
import { GotuuriState } from '@/models/gotuuri-state'
import { User } from '@/models/user'
import { post } from '@/services/axios/axios'
import { updateProfile } from '@/utils/analytics'
import {
  setLoadingProfileUpdate,
  setPostUpdateProfileAction,
  setShowAlert,
} from 'store/actions'

export const usePostUpdateProfile = (dispatch, state: GotuuriState) => {
  const postUpdateProfile = async (
    user: any,
    payment_processor = DEFAULT_PAYMENT_METHOD,
    logUser = true
  ) => {
    dispatch(setLoadingProfileUpdate(true))
    const body: Partial<User> = {
      id: state?.user?.id || user?.id,
      ...(!!user.firstName && { first_name: user.firstName }),
      ...(!!user.lastName && { last_name: user.lastName }),
      ...(!!user.birthDate && { birth_date: user.birthDate }),
      ...(!!user.dni && { dni: user.dni }),
      ...(!!user.address && { address: user.address }),
      ...(!!user.phone && { phone: user.phone.replaceAll(' ', '') }),
      ...(!!user.language && { language: user.language }),
      ...(!!user.country && { country: user.country }),
      ...(!!user.password && { password: user.password }),
      ...(!!user.bookingCountry && { booking_country: user.bookingCountry }),
      ...(!!user.bookingCity && { booking_city: user.bookingCity }),
      ...(!!user.postalCode && { postal_code: user.postalCode }),
    }
    const response = await post({
      path: 'update-profile',
      data: body,
    })
      .then((data: any) => {
        user = {
          ...state.user,
          ...user,
          fullName: `${user?.firstName ?? state.user?.firstName} ${
            user.lastName ?? state.user?.lastName
          }`,
        }
        window.localStorage.setItem('payment_processor', payment_processor)
        if (logUser) {
          window.localStorage.setItem('userData', JSON.stringify(user))
          window.localStorage.setItem('token', JSON.stringify(user.id))
          dispatch(setPostUpdateProfileAction(user))
          updateProfile(user)
          dispatch(
            setShowAlert({
              show: true,
              type: SUCCESS,
              message: 'profile.userUpdated',
            })
          )
          dispatch(setLoadingProfileUpdate(false))
        }
      })
      .catch(() => {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'profile.verifyInformation',
          })
        )
        dispatch(setLoadingProfileUpdate(false))
      })
  }
  const deleteUserAccount = async (userId, deleteComments) => {
    const body = {
      delete_comments: deleteComments,
    }
    const response = await post({
      path: `delete-profile/${userId}`,
      data: body,
    })
      .then(() => {
        return true
      })
      .catch(() => {
        dispatch(
          setShowAlert({
            show: true,
            type: ERROR,
            message: 'profile.cantDeleteAccount',
          })
        )
        return false
      })
    return response
  }

  return { postUpdateProfile, deleteUserAccount }
}
