const en = {
  translation: {
    common: {
      spanish: 'Español',
      english: 'English',
      optional: 'optional',
      continue: 'Continue',
      errors: {
        required: 'Field required',
      },
      countryPA: 'Panama',
      countryCO: 'Colombia',
      countryDOM: 'Dominican Republic',
      mobileApp: 'mobile app',
      website: 'website',
      hello: 'Hello',
    },
    header: {
      title: 'Discover and Book Unique Experiences',
      experienceTitle:
        'Gotuuri: Tours, Activities and Unique Attractions in {{title}}',
      metaTitle: 'Gotuuri: Tours, Activities and Unique Attractions',
      metaDescription:
        'Discover and book the best excursions, activities and authentic experiences in Panama with Gotuuri. Explore and live unforgettable experiences.',
    },
    navbar: {
      home: 'Home',
      aboutUs: 'About us',
      contact: 'Contact',
      language: 'Language',
      download: 'Download our app',
      signUp: 'Sign up',
      signIn: 'Log in',
      signInUp: 'Log in or sign up',
      profile: 'Profile',
      favorites: 'Favorites',
      agenda: 'Agenda',
      signOut: 'Sign out',
      signOutSuccess: 'Signed out successfully',
      giftcards: 'Gift Cards',
    },
    contact: {
      title: 'Contact',
      contactUs: 'Reach out!',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
    },
    faqs: {
      faqs: 'FAQs',
      faqsDescription: 'Frequently Asked Questions',
    },
    aboutUs: {
      title: 'About us',
      titleMobile: 'About us',
      description:
        'Hop on to Gotuuri, your gateway to genuine local experiences! Our platform is a vibrant marketplace connecting you with certified guides, small businesses, and enthusiastic locals ready to unveil the most authentic activities and hidden treasures of their destinations.',
      description2:
        "At Gotuuri, we're all about immersive experiences. Engage with local communities, taste traditional dishes, dance to regional music, and embrace the natural landscapes just as the locals do. Our mission is to not just showcase the world, but to enrich it by empowering local economies. Join us to live deeply, explore responsibly, and help support the communities that make each place unique. Live everything, book everything, and discover the true spirit of every destination with us!",
    },
    notFound: {
      oops: 'OOPS!',
      title: 'Page not found',
      sorry: "Sorry, we couldn't find that page.",
      message: "Make sure you've typed in the URL correctly or go back to",
      homepage: 'gotuuri.com',
    },
    bannerApps: {
      title: 'Download our App',
      description:
        'We are a digital platform where visitors and residents can connect with tourism micro-entrepreneurs, guides and people passionate about our country (tuuris).',
    },
    resumeCard: {
      experienceDetails: 'Experience details',
      cancelationPolicy: 'Cancelation policy',
      knowYourCoverage: 'About your insurance with',
      priceDetail: 'Price details',
      pricePerPackage: 'Price per group',
      subTotal: 'Subtotal',
      couponDiscount: 'Discount applied',
      fee: 'Fee',
      total: 'Total',
      addCoupon: 'Add coupon',
      couponExpired: 'This coupon has expired.',
      couponAffiliateInvalid: 'This coupon is not valid.',
      couponNotElegiblePrice:
        'The experience price exceeds the allowed limit for this coupon. Adjust your selection and try again.',
      couponHasMaxDiscount:
        'The discount percentage has been adjusted, as the original amount exceeds the permitted limit. Continue with your purchase!',
      couponHasMaxUsesPerUser: 'This coupon has limited uses per user. ',
      couponInvalid:
        'The coupon is not valid for this experience. Review the offer conditions.',
      apply: 'Apply',
      addDiscountCoupon: 'Enter discount coupon',
      invalidCoupon: 'Invalid coupon',
      couponRequired: 'Coupon required',
      pricePerSeniors_one: 'Price x {{count}} senior',
      pricePerSeniors_other: 'Price x {{count}} seniors',
      pricePerAdults_one: 'Price x {{count}} adult',
      pricePerAdults_other: 'Price x {{count}} adults',
      pricePerChildren_one: 'Price x {{count}} child',
      pricePerChildren_other: 'Price x {{count}} children',
      pricePerBabies_one: 'Price x {{count}} baby',
      pricePerBabies_other: 'Price x {{count}} babies',
      additionals: 'Add-ons',
      gotuuriCredit: 'Gotuuri credit',
      totalToPay: 'Total to pay',
      rangeDiscount: 'Discount',
    },
    home: {
      topDestinationsPA: 'Top destinations in Panama',
      topDestinationsCO: 'Top destinations in Colombia',
      topDestinationsDOM: 'Top destinations in Dominican Republic',
      topDestinations: 'Top destinations',
      destinations: 'Destinations',
      highlights: 'Highlights',
      allHighlights: 'All highlights',
      allExperiences: 'All experiences',
      viewAll: 'View all',
    },
    giveAnExperience: {
      title: 'The best gift: an unforgettable experience.',
      chooseATheme: 'Choose a theme',
      chooseAnAmount: 'Choose an amount',
      chooseTheDeliveryDate: 'Choose the delivery date',
      enterDeliveryDetails: 'Enter delivery details',
      nameOfTheLuckyOne: 'Name of the lucky one',
      luckyMail: "Recipient 's mail",
      senderDetails: 'Sender details',
      senderName: 'Name',
      senderEmail: 'Email',
      addAPersonalizedMessage: 'Add a personalized message',
      yourMessage: 'Your message',
      immediateDelivery: 'Immediate delivery',
      selectDate: 'Select schedule',
      errors: {
        invalidAmount: 'The amount must be between $5.00 and $200.00',
      },
      buyGiftCard: 'Buy gift card',
    },
    redeem: {
      title: 'Redeem your gift card',
      form: {
        codeLabel: 'Enter the code on your gift card',
        submitButton: 'Redeem',
      },
      redeemError: 'Error redeeming gift card.',
      redeemSuccess: 'Gift card redeemed successfully!',
      checkBalance: 'Check balance',
      info: 'Once you redeem the gift card, the amount will be credited to your Gotuuri account. Your credit will be applied in full to the purchase of your choice. If the purchase is for an amount less than your credit, the difference will remain in your account.',
    },
    balance: {
      title: 'Available balance',
      info: 'This credit will be applied in full and automatically to your next purchase, but you can always choose not to apply it. If the purchase is for an amount less than your credit, the difference will remain in your account. Please note that your Gotuuri credit has no expiration date, is not transferable, and cannot be redeemed for cash.',
    },
    experienceList: {
      priceStart: 'From',
      pricePerGroup: 'Price per group',
    },
    footer: {
      gotuuri: 'Gotuuri',
      AboutUs: 'About us',
      contact: 'Contact',
      support: 'Help',
      blog: 'Blog',
      termsAndConditions: 'Terms and conditions',
      faqs: 'FAQs',
      paymentMethods: 'Payment Methods',
      privacyPolicies: 'Privacy policies',
      giveAnExperience: 'Gift Cards',
      downloadApp: 'Download app',
      tuuriRegister: 'Register as Tuuri',
    },
    searchBar: {
      lookingFor: 'What are you looking for?',
      search: 'Search',
      resultsFound: '{{quantity}} results found',
    },
    checkEmailForm: {
      signInUp: 'Log in or sign up',
      email: 'Email',
      continue: 'Continue',
      signInWith: 'Or continue with',
      errors: {
        required: 'Field required',
        invalidEmail: 'Invalid email format',
      },
      freeCredit:
        'Register with Gotuuri and receive <span>$15 free</span> credits to enjoy our experiences.',
    },
    signInForm: {
      signIn: 'Log in',
      password: 'Password',
      forgotPassword: 'Forgot your password?',
      signInButton: 'Log in',
      signInSuccess: 'Signed in successfully',
      errors: {
        required: 'Required field',
        invalidPassword: '',
        incorrectPassword: 'Incorrect password',
      },
    },
    signUpForm: {
      signUp: 'Sign up',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      phone: 'Phone',
      password: 'Password',
      signUpButton: 'Continue',
      disclosureFirstPart:
        'I have read and accept the <a target="_blank" href="/terms-and-conditions">terms and conditions</a> and the <a target="_blank" href="/privacy-policies">privacy policy</a> of Gotuuri. ',
      disclosureSecondPart: 'terms & conditions.',
      termsConsentError: 'Before registering, please accept our terms.',
      signUpSuccess: 'Signed up successfully',
      errors: {
        required: 'Required field',
        invalidFirstName: 'Must contain at least 3 characters.',
        invalidLastName: 'Must contain at least 3 characters.',
        invalidDni: 'Must contain at least 3 characters.',
        invalidEmail: 'Invalid email format',
        invalidPhone: 'Invalid phone number',
        invalidPassword:
          'Must contain at least 8 characters, 1 uppercase, 1 lowercase, 1 number.',
        verifyInformation:
          'Your sign up could not be completed, please verify the information and try again.',
      },
    },
    signUpSuccess: {
      welcome: 'Welcome to gotuuri',
      description:
        'Where you can discover and book authentic experiences in Panama.',
      description1:
        'We recommend that you complete your profile, so that the Tuuris can contact you.',
      continue: 'Continue',
      description2: 'Complete your profile so that our Tuuris can contact you.',
    },
    experience: {
      description: 'Description',
      detailsTitle: 'Details',
      includeTitle: "What's included?",
      and: 'and',
      details: {
        type: 'Type:',
        duration: 'Duration:',
        durationData_one: '{{count}} hour',
        durationData_other: '{{count}} hours',
        lang: 'Language:',
        difficulty: 'Difficulty:',
        minParticipants: 'Minimum number of participants:',
        maxParticipants: 'Maximum number of participants:',
        meetingPoint: 'Meeting point:',
        private: 'Private',
        shared: 'Shared',
        spanish: 'Spanish',
        english: 'English',
        german: 'German',
        french: 'French',
      },
      difficultyLevels: {
        easy: 'Easy',
        medium: 'Medium',
        hard: 'Hard',
        extreme: 'Extreme',
        notApply: 'Not applicable',
      },
      cancelationPoliciesValues: {
        noRefundable: 'Non-Refundable',
        refundable24:
          'Get a full refund if you cancel at least 24 hours before the experience kicks off.',
        refundable48:
          'Get a full refund if you cancel at least 48 hours before the experience kicks off.',
        refundable72:
          'Get a full refund if you cancel at least 72 hours before the experience kicks off.',
        refundable7:
          'Get a full refund if you cancel at least 7 days before the experience kicks off.',
        refundable15:
          'Get a full refund if you cancel at least 15 days before the experience kicks off.',
      },
      included: {
        photos: 'Photos',
        drinks: 'Drinks',
        equipments: 'Equipment',
        snacks: 'Food',
        tickets: 'Tickets',
        transport: 'Transport',
      },
      recommendations: 'Recommendations',
      noIncludes: 'Not included',
      cancellationPolicies: 'Cancellation policy',
      participantsRequirements: 'Participant requirements',
      additionalQuestions: 'Additional questions',
      additionalDetails:
        "Once your experience is confirmed within 24hrs, we'll send you an email with the contact info of your amazing Tuuri - the local Experience Provider. If you have any more questions, feel free to reach out to us:",
      customerReview: 'Customer reviews',
      viewMoreReviews: 'View more reviews',
      viewLessReviews: 'View less reviews',
      whatsapp: 'Whatsapp',
      email: 'Email',
      location: 'Location',
      viewMore: 'View more',
      showMore: 'Show more',
      showLess: 'Show less',
      supportMessage: 'Hello%20Gotuuri👋🏼%20I%20need%20help.',
      supportMessage2:
        'Hello%20Gotuuri👋🏼%20I%20have%20questions%20regarding%20the%20experience%20{{expName}}%20from%20{{tuuriName}}.',
      chubb:
        'Book with Gotuuri and enjoy coverage from Chubb insurance throughout your entire experience. ',
      lookForChubb: 'Check the terms and insured amounts ',
      here: 'here',
      schedule: 'Schedule:',
      separator: ' at ',
      all_days: 'Every day',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    bookingCard: {
      perGroup: 'per group',
      perPerson_one: 'per person',
      perPerson_other: 'Total',
      total: 'Total',
      selectDate: 'Select schedule',
      selectTime: 'Select time',
      noHoursAvailable: 'No timetables available',
      firstSelectDate: 'First select the schedule',
      firstSelectHour: 'First select the time',
      participants: 'Participants',
      seniors: 'Seniors',
      age65More: 'Ages 65+',
      adults: 'Adults',
      age13More: 'Ages 13+',
      children: 'Children',
      age2to12: 'Ages 2 to 12',
      babies: 'Infants',
      ageLess2: 'Under 2',
      age65: 'Ages 65+',
      perGroupExperience:
        '{{company}} offers this experience for a group of up to {{maxPersons}} people for a price of ${{pricePackage}}.',
      book: 'Book now',
      booking: 'Book',
      schedules: 'View schedules',
      dateAndHour: 'Date and time',
      priceStart: 'per person',
      pricePerGroup: 'Price per group',
      next: 'Next',
      signIn: 'Sign up to book',
      signInMobile: 'Sign up to book',
      addOns: 'Add-ons',
      whyUs: 'Why Gotuuri?',
      accidentInsurance: 'Accident coverage during the experience.',
      cancelationPoliciesValues: {
        noRefundable: 'Non-Refundable.',
        refundable24:
          'Free cancellation if you cancel 24 hours prior to the start time.',
        refundable48:
          'Free cancellation if you cancel 48 hours prior to the start time.',
        refundable72:
          'Free cancellation if you cancel 72 hours prior to the start time.',
        refundable7:
          'Free cancellation if you cancel 7 days prior to the start time.',
        refundable15:
          'Free cancellation if you cancel 15 days prior to the start time.',
      },
      personalizedAttention:
        'Personalized attention via email, phone, or WhatsApp.',
    },
    payment: {
      payWith: 'Pay with',
      creditCard: 'Credit card',
      paypal: 'Paypal',
      billingContactData: 'Billing & Contact Details',
      billingData: 'Billing Details',
      cardNumber: 'Card number',
      ownerName: "Cardholder's name",
      expirationDate: 'Expires (MM/YY)',
      cvvCode: 'Security code',
      name: 'First name',
      lastName: 'Last name',
      address: 'Address',
      country: 'Country or region',
      city: 'City/State/Province',
      missingCity: 'City is required',
      state: 'State',
      postalCode: 'Postal Code',
      email: 'Email',
      phone: 'Phone',
      cardNumberPlaceHolder: 'xxxx-xxxx-xxxx-xxxx',
      ownerNamePlaceHolder: 'Full name',
      expirationDatePlaceHolder: 'MM/YY',
      cvvCodePlaceHolder: 'CVV',
      namePlaceHolder: 'Jhon',
      lastNamePlaceHolder: 'Doe',
      addressPlaceHolder: 'Street, neighborhood, house number',
      statePlaceHolder: 'Address',
      postalCodePlaceHolder: 'Postal code',
      emailPlaceHolder: 'Email',
      invalidEmail: 'Invalid email format',
      invalidFormat: 'Invalid format',
      disclosure:
        "Your request is hoping its way to the Tuuri—the local Experience Provider! They'll take a peek and get back to you within 24 hours. Don't worry, your card won't feel a thing until your request is all set and confirmed.",
      specialChars: 'No numbers or special characters allowed',
      required: 'Field required',
      select: 'Select',
      confirmPayment: 'Send request',
      continuePayment: 'Continue pay',
      pay: 'Pay',
      paymentSuccess: 'Your request has been sent successfully!',
      viewAgenda: 'View agenda',
      paymentError: 'Payment could not be processed. Please try again.',
      creditGotuuri: 'Gotuuri credit',
      buy: 'Buy',
      successfulGiftCardPurchase: 'Successful Gift Card Purchase',
      detailGiftCardPurchase: 'You will receive an email with the details.',
      ThanksForChoosingUs: 'Thanks for choosing us!',
      applyAvailableBalance: 'Apply Gotuuri credit',
      authFailedTitle: 'Transaction Alert: Bank Authorization Issue',
      authFailedConfirm: 'Try another payment method',
      authProcess: 'Authentication Process',
      authProcessDescription:
        "For your security, we require you to follow your bank's instructions before validating the purchase.",
      authProcessing: 'Processing authentication',
      authFailed: 'Failed authentication',
      authIssue:
        'The transaction with this card could not be processed. Please try with another credit card or payment method.',
      personalData: 'Personal information',
    },
    profile: {
      profile: 'Profile',
      personalInformation: 'Personal information',
      userUpdated: 'Personal information updated successfully',
      verifyInformation: 'Please try again.',
      fullName: 'Full name',
      nameLabel: 'Name',
      lastName: 'Last name',
      jhon: 'Jhon',
      doe: 'Doe',
      jhonDoe: 'Jhon Doe',
      phone: 'Phone',
      birthDate: 'Birthdate',
      dni: 'ID/Passport',
      address: 'Address',
      country: 'Country',
      cancel: 'Cancel',
      edit: 'Edit',
      save: 'Save',
      saving: 'Saving',
      selectCountry: 'Select country',
      preferences: 'Preferences',
      language: 'Language',
      security: 'Security',
      changePassword: 'Change password',
      oldPassword: 'Current password',
      newPassword: 'New password',
      repeatPassword: 'Confirm password',
      disableAccount: 'Deactivate or delete account',
      aboutDeleteAccount: 'About account deletion requests',
      deleteAccountDisclosure1:
        'If at the time of requesting to deactivate or delete your account, you have a confirmed reservation, either as a Tuuri or a customer, we will not be able to fulfill the request. It is important to first cancel the reservation, taking into account the applicable cancellation policies, in order for the request to be effective.',
      deleteAccountDisclosure2:
        'If at the time of requesting to deactivate or delete your account, you have a confirmed reservation, either as a Tuuri or a customer, we will not be able to carry out the request. It is important to first cancel the reservation, taking into account the applicable cancellation policies, in order for the request to be effective.',
      whyDeleteAccount: 'Why do you want to delete your account?',
      deleteReason1: "I didn't like Gotuuri.",
      deleteReason2: "I don't give enough use to my account.",
      deleteReason3: 'I want to delete a duplicated account.',
      deleteReason4: 'Other reasons.',
      deleteAccount: 'Delete account',
      deleteAccountConfirmation:
        'You will proceed to delete your account, this action cannot be reversed. Are you sure you want to continue',
      noCancel: 'No, cancel',
      yesDelete: 'Yes, delete',
      accountDeleted: 'Account successfully deleted.',
      cantDeleteAccount:
        "You can't delete the account, you have pending or in progress experiences.",
      goodbyeMessage:
        'We will miss you, but if you change your mind, we will be here.',
      errors: {
        generalError: 'Error: Please verify information',
        emptyFields: 'This field cannot be empty.',
        specialChars: 'No numbers or special characters allowed',
        invalidPassword: 'Incorrect password.',
        passwordNotMatch: 'The passwords do not match',
      },
      creditAndGiftCard: 'Credits and gift cards',
      buyGiftCard: 'Buy gift card',
      redeem: 'Redeem a gift card',
      balance: 'Available balance',
    },
    search: {
      noSearchResults: 'No results found for your search',
      translationWordsCorrect: 'Make sure all words are spelled correctly.',
      translationUseGeneralKeywords: 'Try more general keywords.',
    },
    filter: {
      filters: 'Filters',
      destination: 'Destination',
      country: 'Country',
      language: 'Language',
      properties: 'Properties',
      privateTours: 'Private tours',
      groupTours: 'Shared tours',
      ratingInt: 'Rating 4+ stars',
      includeTransport: 'Includes transportation',
      pastimes: 'Pastimes',
      acceptsChildren: 'Accepts children',
      spanish: 'Spanish',
      english: 'English',
      german: 'German',
      french: 'French',
      hour_one: '{{count}} hrs',
      hour_other: '{{count}} hrs',
      maximumHours: '{{hour}}+ hrs',
      whereAreWeGoing: 'Where are we going?',
      participants: 'Participants',
      categories: 'Categories',
      duration: 'Duration',
      rangePrice: 'Price Range',
      deleteFilters: 'Remove filters',
      applyFilter: 'Apply filters',
      tryAdjustingFilters:
        ' Try adjusting your filters or using different search terms.',
    },
    selectDate: {
      day: 'Day',
      month: 'Month',
      year: 'Year',
    },
    recoverPasswordForm: {
      recoverPassword: 'Recover password',
      email: 'Email',
      description:
        'We will send you a temporary password to the email associated with your account, which you can use to reset your password.',
      recoverButton: 'Recover',
      goBackButton: 'Back',
    },
    rating: {
      veryBad: 'Very bad',
      bad: 'Bad',
      regular: 'Regular',
      good: 'Good',
      veryGood: 'Very good',
    },
    agendaPage: {
      agenda: 'Agenda',
      reservedExperiences: 'Purchased experiences',
      upcomingPurchases: 'Upcoming bookings',
      pastPurchases: 'Past & canceled',
      nothingReserved:
        "You haven't scheduled any experiences yet. Start exploring!",
      purchaseCard: {
        tuuri: 'Tuuri',
        date: 'Date',
        orderNumber: 'Order number',
        participants: 'Participants',
        total: 'Total',
        experienceNotAvailable: 'Experience not available',
        tuuriNotAvailable: 'Tuuri not available',
      },
      statusBadge: {
        statusCreated: 'Pending',
        statusConfirmed: 'Confirmed',
        statusInProgress: 'In progress',
        statusCompleted: 'Completed',
        statusCanceledByCustomer: 'Canceled',
        statusCanceledByTuuri: 'Canceled by tuuri',
        statusCanceledByAdministration: 'Canceled by administration',
        unknown: 'Unknown',
      },
      purchaseButtons: {
        purchaseCanceled: 'Experience canceled',
        cancelButton: {
          cancel: 'Cancel',
          disclosure:
            'If you need to cancel beyond the free cancellation period due to a Force Majeure event, as outlined in our Terms and Conditions, kindly reach out to us at help@gotuuri.com for assistance.',
          confirmation: 'Are you sure you want to cancel this experience?',
          yes: 'Yes',
          no: 'No',
        },
        reviewButton: {
          rate: 'Review',
          rateYourExperience: 'Rate your experience',
          ratingSelect: 'Please rate your experience in a scale of 1 to 5.',
          ratingComment:
            'If you would like to leave a public comment, so that others can see it, you can do it here.',
          ratingPrivateComment:
            'If you have any other comments or suggestions, you can leave them here.',
          rateSend: 'Send',
          thanksFirst: '¡Thank you for your',
          thanksSecond: 'feedback!',
          continue: 'Continue',
          error: 'Your rating could not be submitted',
        },
        viewMoreButton: {
          type: 'Type',
          private: 'Private',
          shared: 'Shared',
          schedule: 'Schedule',
          date: 'Date',
          participants: 'Participants',
          adults: 'Adults',
          children: 'Children',
          babies: 'Babies',
          addons: 'Add-ons',
          pickup: 'Pickup point',
          meeting: 'Meeting point',
          reference: 'Reference',
          viewDetails: 'View details',
          order: 'Order',
          tuuri: 'Tuuri',
          total: 'Total',
        },
      },
    },
    destinationPage: {
      viewMore: 'View more',
      mayBeInterested: 'You may also be interested in',
      experienceQuantity: '{{quantity}} experiences',
    },
    bookingDetailsPage: {
      experienceDetails: 'Experience details',
      hour: 'Time: ',
      participants: 'Participants: ',
      date: 'Date: ',
      meetingPoint: 'Meeting point',
      pickupPoint: 'Enter your pick-up address:',
      reference: 'Reference',
      pointOfReference: 'More details',
      referencePlaceHolder: 'Pick-up address (E.g. Hotel name or address).',
      total: 'Total',
      continue: 'Continue',
      principalData: 'Principal insured data',
      firstName: 'First name',
      lastName: 'Last name',
      birthDate: 'Birthdate',
      minDateError: 'Must be 18 years of age or older',
      maxDateError: 'Must be under 90 years of age',
      birthDateError: 'Invalid date',
      dni: 'ID/Passport',
      email: 'Email',
      phone: 'Phone',
      address: 'Address',
      companionData: 'Other participants data',
      companion: 'Participant {{index}}',
      dataDisclosure:
        'We need these details to hook you up with insurance coverage, keeping you safe and sound during your entire adventure!',
      continueWithPayment: 'Continue with payment',
      requiredParticipantsFields: 'Required fields',
      updateProfile: 'Update profile with this data',
      useForChubb: 'Use billing details as contact information',
      insuranceData: 'Insurance & Contact Details',
      insuranceDataDescription:
        'We need these details to hook you up with insurance coverage, keeping you safe and sound during your entire adventure!',
    },
    uniqueDatesRow: {
      dateFormat: "EEEE MMMM dd',' yyyy",
    },
    favoritePage: {
      title: 'Favorites',
      noFavorites:
        "You haven't added anything to your favorites yet. Start exploring!",
    },
    giftCard: {
      helloUser: 'Hello {{user}}',
      giftCardSender: '(Your Name)',
      giftCardMessage:
        '{{senderName}} has sent you a gift card to dive into the authentic adventures available on our platform. Seize this chance to create some truly unforgettable memories!.',
      yourCode: 'Your code is:',
      messageHere: 'Your message here',
      redeemYourCardIn: 'Redeem your gift card at our',
      redeemYourCardInBlock1: 'The gift card is valid for redemption on our',
      redeemYourCardInOr: 'or',
      redeemYourCardInBlock2:
        ' for the next 6 months. After redemption, the credit has no expiration date.',
      footerTimeDisclaimer:
        'The gift card is valid for redemption on our website or mobile application for the next 6 months. After redemption, the credit has no expiration date.',
      footerDisclaimer:
        'To redeem the gift card, go to the "Gift Cards" section in your profile and choose "Redeem Gift Card." Once you redeem the gift card with the code in this email, the credit will be added to your account and will be automatically applied to your next purchase, however you can always decide not to apply it.',
      giftCardRecipient: '(lucky name)',
      stepsToRedeemYourGiftCard: 'Steps to redeem your gift card',
      step1: 'Enter your profile from our website or mobile application.',
      step2: 'Go to the Credits and gift cards section.',
      step3: 'Choose the "redeem gift card" option.',
      step4: 'Enter your gift card code.',
      step5:
        "That's it! Now you will have the credit associated to your user and it will be automatically applied to your next reservation.",
    },
    unSubscribePage: {
      emailPreferences: 'Email preferences',
      emailPreferencesDescription:
        'By clicking on "Unsubscribe," you will stop receiving marketing emails from Gotuuri. If you prefer to maintain your subscription, simply click on "Continue receiving emails."',
      successfullyUnsubscribe: 'You have successfully unsubscribed.',
      successfullyUnsubscribeDescription:
        "From now on, you will no longer receive marketing emails from Gotuuri. We'll miss you, it won't be the same without you!",
      changeOfMindMessage: `If you've changed your mind, you can continue receiving emails by clicking on "Resubscribe."`,
      unSubscribe: 'Unsubscribe',
      continueReceivingEmail: 'Continue receiving emails',
      reSubscribe: 'Resubscribe',
      welcomeBack: 'Welcome back!',
      thanksContinueReceivingEmails:
        'You have chosen to continue to receive our email communications. Thank you for remaining part of our community!',
    },
    map: {
      youMustSelectAValidLocationWithin: 'Please select a valid location in',
    },
    redirect3ds: {
      allRight: '¡All right!',
      allRightDescription: 'Click continue to complete your purchase process.',
    },
    tuuriRegister: {
      registerAsTuuri: 'Register as Tuuri',
      title: 'Got incredible experiences to share? Join Gotuuri and connect with thousands of travelers eager to explore unique destinations.',
      benefitsForYou: 'Benefits for you:',
      benefitTitle1: 'Support: ',
      benefit1: "We're here for you, by phone, WhatsApp, or email.",
      benefitTitle2: 'Mobile app: ',
      benefit2: 'Manage your business on the go.',
      benefitTitle3: 'Tailored recommendations: ',
      benefit3: "We'll help you stand out by improving your offerings.",
      createPassword: 'Create password',
      submitButton: 'Register as Tuuri',
      modalTitle: {
        success: 'Tuuri registered succesfully.',
        genericError: 'Register failed.',
        conflictError: 'Register failed.',
      },
      modalBody: {
        success: 'We will send you more details via email.',
        genericError: 'Please check that all your data is correct and try again.',
        conflictError: 'The email is already registered. Please contact support.',
      }
    },
  },
}

export default en
